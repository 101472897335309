<template>
  <div class="animated fadeIn">
    <v-col cols="12">
            <person-tables 
      :get="'/clinic/hr/receptions'" 
      :job="'reception'" 
      :faJob="'پذیرش'" 
      :Fields="Fields"
      :excelFields="excelFields"
      :new="'newReception/'" 
      :edit="'editReception/'" 
      :title="'کادر پذیرش'"/>
    </v-col>
  </div>
</template>
<script>
const PersonTables = () => import("@/components/hr/PersonTables");

export default {
  components: {
    PersonTables
  },
  data() {
    return {
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "role", label: "نقش" },
        { key: "username", label: "نام کاربری" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "status", label: "وضعیت " },
        { key: "operation", label: "مدیریت " },
      ],
      excelFields: [
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "username", label: "نام کاربری" },
        { key: "role", label: "نقش" },
        { key: "mobile", label: "تلفن همراه" },
        { key: "status", label: "وضعیت " },
      ],
    };
  }
};
</script>

